<template>
  <div class="page">
    <div class="page-main">
      <div class="alert" v-if="$route.query.pushStatus == '0'">{{$t('sipAccount.tips')}}</div>
      <el-table
        class="tr-stretching-table"
        border
        :data="accountList"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column prop="accountNo" :label="$t('device.account')" width="150">
          <template slot-scope="scope">{{ $t('device.account') + scope.row.accountNo }}</template>
        </el-table-column>
        <el-table-column prop="sipUserId" :label="$t('sipAccount.userName')">
          <template slot-scope="scope">
            <el-select
              popper-class="select-wrap"
              v-model="scope.row.id"
              :placeholder="$t('pleaseSelect')"
              @change="accountIdChange($event, scope)"
              clearable
              filterable
              style="width:100%"
            >
              <el-option
                v-for="item in getUserIdList(userIdList, scope.$index)"
                :key="item.id"
                :label="item.sipUserId"
                :value="item.id"
                class="account-list"
              >
                <el-tooltip effect="dark" :content="$t('sipAccount.assigned')" placement="left" :disabled="!item.status">
                  <i
                    :class="['el-icon-s-custom', { 'text-success': !!item.status }, 'icon']"
                    :title="accountStatus[item.status]"
                  ></i>
                </el-tooltip>
                <span :title="item.sipUserId"
                  >{{ item.sipUserId
                  }}<span v-if="item.accountName">({{ item.accountName }})</span></span
                >
                <span class="right"
                  >{{ item.serverName
                  }}<span v-if="item.sipServer">({{ item.sipServer }})</span></span
                >
              </el-option>
            </el-select>
            <div class="el-form-item__error" style="position:relative" v-show="scope.row.errText">
              <span class="inBlock width300 ml5">{{ scope.row.errText }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="serverName" :label="$t('sipServer.serverName')">
          <template slot-scope="scope">{{
            scope.row.serverName ? scope.row.serverName : '-'
          }}</template>
        </el-table-column>
        <el-table-column prop="sipServer" :label="$t('sipServer.sipServer')">
          <template slot-scope="scope">{{
            scope.row.sipServer ? scope.row.sipServer : '-'
          }}</template>
        </el-table-column>
      </el-table>
      <div class="footer-btns" style="margin-top: 100px">
        <el-button class="footer-btn"
          type="primary"
          plain
          @click="$router.go(-1)"
        >{{$t('cancel')}}</el-button>
        <el-button class="footer-btn"
          :disabled="accountList.length ? false : true"
          type="primary"
          @click="submit"
        >{{$t('save')}}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { pushDeviceConfig } from '@/api/setParam'
import { getNonUniqueIds } from '@/plugins/methods'
import { accountConfigQuery } from '@/api/sipAccount'
import { deviceAccountList, deviceAccountAssign } from '@/api/device'

export default {
  name: 'DeviceAccount',
  data() {
    return {
      accountList: [],
      oldAccountList: [],
      userIdList: [],
    }
  },
  computed: {
    ...mapGetters('dist', ['accountStatus']),
  },
  created() {
    this.getData(this.$route.query.id)
    this.getUserId()
  },
  methods: {
    // 账户唯一性校验
    accountUniquevalid() {
      let repeatIds = getNonUniqueIds(this.accountList, 'accountId')
      this.accountList.forEach((item) => {
        if (item.accountId && repeatIds.indexOf(item.accountId) > -1) {
          item.errText = this.$t('device.userNameRule')
        } else {
          item.errText = ''
        }
      })
      let validStatus = this.accountList.every((item) => {
        return item.errText
      })
      return validStatus
    },
    getData(id) {
      deviceAccountList({ id }).then(({ data }) => {
        this.accountList = data.result.rows
        this.oldAccountList = JSON.parse(JSON.stringify(this.accountList))
      })
    },
    getUserId() {
      accountConfigQuery({
        siteId: this.$route.query.siteId,
        deviceId: this.$route.query.id
      }).then((res) => {
        // console.log(res);
        if (res.data.code == 0) {
          this.userIdList = res.data.result.rows
        }
      })
    },
    // 保存
    submit() {
      let validStatus = this.accountUniquevalid()
      if (validStatus) {
        return
      }
      let req = []
      this.accountList.forEach((e) => {
        req.push({
          accountId: e.id,
          deviceId: this.$route.query.id,
          no: e.accountNo,
        })
      })
      if (!req.length) {
        req = [
          {
            deviceId: this.$route.query.id,
          },
        ]
      }
      deviceAccountAssign(req)
        .then(({ data }) => {
          if (this.$route.query.pushStatus == 0) {
            this.$message.success(data.message)
            this.$router.go(-1)
          } else {
            this.push()
          }
        })
        .catch(() => {})
    },
    push() {
      this.$confirm(this.$t('sitParam2.tipStr'), this.$t('sitParam2.tipTitle'), {
        confirmButtonText: this.$t('yes'),
        cancelButtonText: this.$t('no'),
        type: 'success',
        center: true,
      })
        .then(() => {
          pushDeviceConfig(this.$route.query.id)
            .then(({ data }) => {
              this.$message.success(data.message)
              this.$router.go(-1)
            })
            .catch(() => {})
        })
        .catch(() => {
          this.$router.go(-1)
        })
    },
    accountIdChange(event, scope) {
      if (event) {
        let account = this.geAccount(event, scope.$index)
        scope.row.serverName = account.serverName
        scope.row.sipServer = account.sipServer
      } else {
        scope.row.serverName = ''
        scope.row.sipServer = ''
      }
      this.accountUniquevalid()
    },
    // 查询Account
    geAccount(id, index) {
      let object = {}
      this.getUserIdList(this.userIdList, index).forEach((e) => {
        if (id == e.id) {
          object = e
        }
      })
      return object
    },
    getUserIdList(arr, index) {
      // 需求说明： 账号有两种来源。一种来自edm，对所有话机所有账号可见。一种来自某个话机上报，只对该设备且该账号可见
      let newArr = JSON.parse(JSON.stringify(arr))
      let currentBindAccount = this.oldAccountList[index]
      let bound = currentBindAccount && currentBindAccount.accountId
      // if (index == 0) {
      //   console.log(JSON.stringify(currentBindAccount))
      // }

      // 1. 让当前绑定的账号显示在最上面
      if (bound) {
        newArr.unshift(currentBindAccount)
      }

      // 2. 去除重复account
      if (bound) {
        for (let i = 1; i < newArr.length; i++) {
          if (newArr[i].id === currentBindAccount.id) {
            currentBindAccount.status = newArr[i].status
            currentBindAccount.accountName = newArr[i].accountName
            newArr.splice(i, 1)
            break
          }
        }
      }

      // 3. 删除除了当前绑定的账号之外的origin=2的账号
      let idToDelete = []
      let indexStart
      if (bound) {
        indexStart = 1
      }else{
        indexStart = 0
      }
      for (let i = indexStart; i < newArr.length; i++) {
        if(newArr[i].origin===2) {
          idToDelete.push(newArr[i].id)
        }
      }
      for (let i = 0; i < idToDelete.length; i++) {
        for (let j = 0; j < newArr.length; j++) {
          if (idToDelete[i] === newArr[j].id) {
            newArr.splice(j, 1)
            break
          }
        }
      }
      newArr = newArr.sort((a, b) => a.accountName - b.accountName)
      return newArr
    },
  },
}
</script>

<style lang="scss" scoped>
@include form-page;
.page-main {
  padding: 20px 140px 20px;
}
.alert {
  height: 54px;
  background: #FFF7DB;
  border: 1px solid #F2B136;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  color: #F5A75A;
  text-align: center;
  line-height: 54px;
}
.tr-stretching-table {
  margin-top: 30px;
}
.account-list {
  min-width: 300px;
  .icon {
    margin-right: 5px;
  }
  .right {
    float: right;
  }
}
</style>
